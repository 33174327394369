'use client';

import { useEffect } from 'react';

export function Optimizely() {
  useEffect(() => {
    (window as any).optimizely = (window as any).optimizely || [];
    (window as any).optimizely.push(['activate']);
  }, []);

  return null;
}
