import(/* webpackMode: "eager" */ "/vercel/path0/apps/marketing-website/app/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/analytics/src/lib/analytics-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["componentsDictionary"] */ "/vercel/path0/libs/shared/contentful/dictionaries/src/lib/components-dictionary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["COMPONENT_DICTIONARY"] */ "/vercel/path0/libs/shared/contentful/providers/src/lib/contentful-components/contentful-components.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/contentful/providers/src/lib/contentful-content-provider/contentful-content-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/shared/contentful/ui/draft-mode-notification/src/lib/draft-mode-exit-link/draft-mode-exit-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieManager"] */ "/vercel/path0/libs/shared/third-party-scripts/src/lib/cookie-manager/cookie-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManager"] */ "/vercel/path0/libs/shared/third-party-scripts/src/lib/google-tag-manager/google-tag-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OptimizelyScript"] */ "/vercel/path0/libs/shared/third-party-scripts/src/lib/optimizely/optimizely-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Optimizely"] */ "/vercel/path0/libs/shared/third-party-scripts/src/lib/optimizely/optimizely.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RingCentralLiveChatScript"] */ "/vercel/path0/libs/shared/third-party-scripts/src/lib/ring-central-live-chat/ring-central-live-chat-scripts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/vercel/path0/libs/shared/utilities/react-query-provider/src/lib/react-query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../styles/assets/fonts/oatmeal-pro/OatmealPro-Regular.woff\",\"weight\":\"400\"},{\"path\":\"../../../styles/assets/fonts/oatmeal-pro/OatmealPro-Medium.woff\",\"weight\":\"500\"},{\"path\":\"../../../styles/assets/fonts/oatmeal-pro/OatmealPro-SemiBold.woff\",\"weight\":\"600\"}],\"variable\":\"--font-body\"}],\"variableName\":\"fontBody\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../styles/assets/fonts/oatmeal-pro/OatmealPro-Regular.woff\",\"weight\":\"400\"},{\"path\":\"../../../styles/assets/fonts/oatmeal-pro/OatmealPro-Medium.woff\",\"weight\":\"500\"},{\"path\":\"../../../styles/assets/fonts/oatmeal-pro/OatmealPro-SemiBold.woff\",\"weight\":\"600\"}],\"variable\":\"--font-display\"}],\"variableName\":\"fontDisplay\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../styles/assets/fonts/oatmeal-pro/OatmealPro-Regular.woff\",\"weight\":\"400\"},{\"path\":\"../../../styles/assets/fonts/oatmeal-pro/OatmealPro-Medium.woff\",\"weight\":\"500\"},{\"path\":\"../../../styles/assets/fonts/oatmeal-pro/OatmealPro-SemiBold.woff\",\"weight\":\"600\"}],\"variable\":\"--font-ui\"}],\"variableName\":\"fontUi\"}");
