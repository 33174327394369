import { Link } from '@pbx/shared/ui/common/link';

import { TAwardsProps } from './awards.types';

export function Awards({ awards }: TAwardsProps) {
  if (!Array.isArray(awards) || !awards?.length) {
    return null;
  }

  return (
    <ul className="flex flex-row items-center gap-5">
      {awards.map(({ link, icon, newTab }, index) => (
        <li
          key={`${link}-${index}`}
          className="transition-opacity hover:opacity-80"
        >
          <Link href={link?.href} newTab={newTab}>
            {icon && (
              <img
                loading="lazy"
                src={icon.src}
                alt={icon.alt}
                width={icon.width}
                height={icon.height}
                className="max-h-6 max-w-full"
              />
            )}
          </Link>
        </li>
      ))}
    </ul>
  );
}
