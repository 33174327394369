import { useState, useEffect } from 'react';

import { largeHeaderVariants } from './large-header.variants';
import { TLargeHeaderProps } from './large-header.types';
import { TopMenu } from '../top-menu/top-menu';
import { MainMenu } from '../main-menu/main-menu';
import { HIDE_HEADER_SCROLL_BREAKPOINT } from '../header.constants';

export function LargeHeader({
  topMenu,
  mainMenu,
  logo,
  findPropertyPage,
  loginPage,
  bookValuationPage,
  isLoggedIn,
  reviewLinks,
}: TLargeHeaderProps) {
  const [isTopMenuVisible, setIsTopMenuVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsTopMenuVisible(currentScrollY < HIDE_HEADER_SCROLL_BREAKPOINT);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={largeHeaderVariants({ isTopMenuVisible })}>
      <div className="bg-background-tertiary">
        <TopMenu links={topMenu} />
      </div>
      <div className="bg-brand-background-primary container sticky w-full">
        <MainMenu
          logo={logo}
          mainMenu={mainMenu}
          findPropertyPage={findPropertyPage}
          loginPage={loginPage}
          bookValuationPage={bookValuationPage}
          isLoggedIn={isLoggedIn}
          reviewLinks={reviewLinks}
        />
      </div>
    </div>
  );
}
