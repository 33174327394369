'use client';
import { usePathname } from 'next/navigation';

import { TDraftNotificationProps } from '../draft-mode-notification.types';

function DraftModeExitLink({ apiSlug }: TDraftNotificationProps) {
  const pathname = usePathname();
  const basePath = process.env.NEXT_PUBLIC_PB_BASE_PATH || '';

  return (
    <a
      href={`${basePath}/${apiSlug}/disable-draft?redirect=${pathname}`}
      className="underline"
    >
      Exit
    </a>
  );
}

export default DraftModeExitLink;
