'use client';
import { Footer } from '@pbx/shared/ui/layout/footer';
import { Header } from '@pbx/shared/ui/layout/header';
import { HeaderMortgages } from '@pbx/shared/ui/layout/header-mortgages';

export const componentsDictionary = {
  headerPrimary: Header,
  footerPrimary: Footer,
  headerMortgages: HeaderMortgages,
};
