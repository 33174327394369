import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useState, useEffect } from 'react';

import { LinkButton } from '@pbx/shared/ui/common/link-button';
import { Link } from '@pbx/shared/ui/common/link';
import { Text } from '@pbx/shared/ui/common/text';
import { Icon } from '@pbx/shared/ui/common/icon';
import { screenTokens } from '@pbx/styles/core/screen';

import { TMobileDropdownMenu } from './mobile-dropdown-menu.types';

export const LARGE_SCREEN_BREAKPOINT = parseInt(screenTokens.lg.min);

export function MobileDropdownMenu({
  mainMenu,
  primaryAction,
  secondaryAction,
}: TMobileDropdownMenu) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = (open: boolean) => {
    setIsMenuOpen(open);
  };

  useEffect(() => {
    const handleResize = () => {
      if (isMenuOpen && window.innerWidth >= LARGE_SCREEN_BREAKPOINT) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMenuOpen]);

  return (
    <DropdownMenu.Root
      onOpenChange={handleMenuOpen}
      open={isMenuOpen}
      modal={true}
    >
      <DropdownMenu.Trigger
        className="focus-visible:outline-none"
        aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
      >
        {isMenuOpen ? (
          <Icon
            name="remove"
            width={24}
            className="text-foreground-highlight"
          />
        ) : (
          <Icon name="menu" width={24} className="text-foreground-highlight" />
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <>
          <DropdownMenu.Content
            sticky="partial"
            className="data-[side=bottom]:animate-slideDownAndFade z-20 mt-5 flex w-[100vw] flex-col will-change-[opacity,transform]"
          >
            <DropdownMenu.Item
              key={1}
              className="bg-background-tertiary p-3 focus-visible:outline-none md:hidden lg:hidden"
              onClick={() => setIsMenuOpen(false)}
            >
              {primaryAction?.link?.href && (
                <LinkButton
                  variant="primary"
                  className="w-full p-3"
                  newTab={!!primaryAction?.newTab}
                  link={primaryAction?.link}
                  label={primaryAction.label || ''}
                />
              )}
            </DropdownMenu.Item>

            {mainMenu.map((item, index) => (
              <DropdownMenu.Item
                key={index + 1}
                className="bg-background-primary hover:bg-background-tertiary p-3 transition-colors focus-visible:outline-none"
                onClick={() => setIsMenuOpen(false)}
              >
                <Link
                  className="py-3"
                  href={item?.link?.href}
                  newTab={item?.newTab}
                >
                  <Text variant="heading" size="smaller">
                    {item?.label}
                  </Text>
                </Link>
              </DropdownMenu.Item>
            ))}

            <DropdownMenu.Item
              className="focus-visible:outline-none"
              onClick={() => setIsMenuOpen(false)}
            >
              <Link
                href={secondaryAction?.link?.href}
                newTab={!!secondaryAction?.newTab}
                className="bg-background-primary text-foreground-action hover:bg-background-tertiary flex items-center gap-3 p-3 transition-colors"
              >
                <Icon name="arrow-forward" width={24} />
                <Text variant="heading" size="smaller">
                  {secondaryAction?.label || ''}
                </Text>
              </Link>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
          <div className="bg-background-primary absolute top-0 h-[100vh] w-[100vw] opacity-95"></div>
        </>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
