'use client';

import { ShapeTop } from '@pbx/shared/ui/shapes/shape-top';

import { Awards } from './awards/awards';
import { Menus } from './menus/menus';
import { PaymentMethods } from './payment-methods/payment-methods';
import { SecondaryMenu } from './secondary-menu/secondary-menu';
import { Socials } from './socials/socials';
import { Copyright } from './copyright/copyright';
import { TFooterProps } from './footer.types';
import { styleVariants } from './footer.variants';

export function Footer({
  menus,
  secondaryMenu,
  socialMedia,
  paymentMethods,
  awards,
  copyright,
  rightsReserved,
  variant = 'default',
}: TFooterProps) {
  return (
    <footer>
      <ShapeTop shape={1} className={styleVariants({ variant })}>
        <div className="sm:py-8 md:py-12 lg:py-12">
          <div className="container grid gap-8">
            <Menus menus={menus} />

            <div className="border-border-visual border-b" />

            <div className="flex flex-wrap justify-between gap-8 sm:flex-col">
              <SecondaryMenu secondaryMenu={secondaryMenu} />
              <Socials socials={socialMedia} />
            </div>

            <PaymentMethods paymentMethods={paymentMethods} />

            <div className="flex flex-wrap justify-between gap-4 sm:flex-col">
              <Copyright
                copyright={copyright}
                rightsReserved={rightsReserved}
              />
              <Awards awards={awards} />
            </div>
          </div>
        </div>
      </ShapeTop>
    </footer>
  );
}
