import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useState, useEffect } from 'react';

import { Icon } from '@pbx/shared/ui/common/icon';
import { LinkButton } from '@pbx/shared/ui/common/link-button';

import { TSmallNavigationMenu } from './small-navigation-menu-types';
import { MenuAccordion } from '../menu-accordion/menu-accordion';
import { LARGE_SCREEN_BREAKPOINT } from '../header.constants';

export function SmallNavigationMenu({
  mainMenu,
  topMenu,
  bookValuationPage,
}: TSmallNavigationMenu) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Remove the 15px right margin added by shadcdn/ui, when modal opened
  useEffect(() => {
    const propertyValue = isMenuOpen ? '0px' : null;
    document.body.style.setProperty('margin-right', propertyValue, 'important');
  }, [isMenuOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (isMenuOpen && window.innerWidth >= LARGE_SCREEN_BREAKPOINT) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMenuOpen]);

  const hasPrimaryButton = bookValuationPage?.link?.href;

  return (
    <DropdownMenu.Root
      onOpenChange={setIsMenuOpen}
      open={isMenuOpen}
      modal={true}
    >
      <DropdownMenu.Trigger
        className="text-brand-foreground-primary focus-visible:outline-none"
        aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
      >
        <Icon name={isMenuOpen ? 'remove' : 'menu'} width={24} />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sticky="partial"
          className="data-[side=bottom]:animate-slideDownAndFade z-20 mt-3 flex w-[100vw] flex-col will-change-[opacity,transform]"
        >
          {hasPrimaryButton && (
            <div className="bg-brand-background-primary p-3 focus-visible:outline-none md:hidden">
              <LinkButton
                variant="primary"
                className="w-full p-3"
                newTab={!!bookValuationPage.newTab}
                link={bookValuationPage.link}
                label={bookValuationPage.label || ''}
                inverted
              />
            </div>
          )}
          <MenuAccordion
            mainMenu={mainMenu}
            topMenu={topMenu}
            className={hasPrimaryButton ? 'sm:max-h-[calc(75vh-72px)]' : ''}
          />
          <div className="bg-brand-background-primary h-[100vh] w-[100vw] opacity-95"></div>
          <DropdownMenu.Arrow
            offset={10}
            width={48}
            height={12}
            className="fill-none pl-[0.75rem]"
          />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
