import { TSocialsProps } from './socials.types';

export function Socials({ socials }: TSocialsProps) {
  if (!Array.isArray(socials) || !socials?.length) {
    return null;
  }

  return (
    <ul className="flex flex-row items-center gap-3">
      {socials.map(({ link, icon }, index) => (
        <li
          key={`${link}-${index}`}
          className="transition-opacity hover:opacity-80"
        >
          <a
            href={link.href}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {icon && (
              <img
                src={icon.src}
                alt={icon.alt}
                width={icon.width}
                height={icon.height}
                loading="lazy"
                className="max-h-6 max-w-full"
              />
            )}
          </a>
        </li>
      ))}
    </ul>
  );
}
