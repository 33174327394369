import { TPaymentMethodsProps } from './payment-methods.types';

export function PaymentMethods({ paymentMethods }: TPaymentMethodsProps) {
  if (!Array.isArray(paymentMethods) || !paymentMethods?.length) {
    return null;
  }

  return (
    <ul className="flex flex-row flex-wrap items-center gap-4">
      {paymentMethods.map(({ src, alt, width, height }, index) => (
        <li key={`${alt}-${index}`} className="">
          <img
            src={src}
            alt={alt}
            width={width}
            height={height}
            loading="lazy"
            className="h-6 max-w-max"
          />
        </li>
      ))}
    </ul>
  );
}
