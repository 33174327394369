import { cva } from 'class-variance-authority';

export const headerVariants = cva(
  'bg-background-primary w-full shadow-[0px_4px_8px_1px_rgba(0,0,0,0.08)] fadeIn top-0 z-30 sticky transition-all duration-300 min-h-[64px] flex items-center',
  {
    variants: {
      isHeaderVisible: {
        true: 'opacity-100 translate-y-0',
        false: 'opacity-0 -translate-y-full',
      },
    },
  }
);
