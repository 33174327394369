import { cva } from 'class-variance-authority';

export const styleVariants = cva('', {
  variants: {
    variant: {
      default:
        '[&_h3]:text-foreground-highlight [&_a]:text-foreground-primary [&_p]:text-foreground-secondary bg-background-tertiary',
      mortgages:
        '[&_h3]:text-brand-foreground-action [&_a]:text-brand-foreground-action [&_p]:text-brand-foreground-action bg-brand-background-primary',
    },
  },
});
