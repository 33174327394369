import { cva } from 'class-variance-authority';

export const liVariants = cva('', {
  variants: {
    image: {
      true: 'transition-opacity hover:opacity-80',
      false: '',
    },
  },
});

export const ulVariants = cva('', {
  variants: {
    image: {
      true: 'flex flex-row flex-wrap gap-5 items-center',
      false: 'flex flex-col gap-3',
    },
  },
});
