import { cva } from 'class-variance-authority';

export const baseAndSmallHeaderVariants = cva(
  'min-h-[72px] bg-brand-background-primary shadow-[0px_4px_8px_1px_rgba(0,0,0,0.08)] fadeIn flex justify-between items-center container top-0 z-30 sticky transition-all duration-300 gap-2',
  {
    variants: {
      isHeaderVisible: {
        true: 'opacity-100 translate-y-0',
        false: 'opacity-0 -translate-y-full',
      },
    },
  }
);
