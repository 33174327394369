import { TMenu } from '@pbx/shared/contentful/helpers';

export function groupByMenuBreaks(menu: TMenu[]): (TMenu | TMenu[])[] {
  const transformedMenu: (TMenu | TMenu[])[] = [];
  let currentGroup: TMenu[] = [];

  for (let i = 0; i < menu.length; i++) {
    const item = menu[i];

    if (item.break) {
      currentGroup.push(item);
      transformedMenu.push(currentGroup);
      currentGroup = [];
    } else {
      if (i < menu.length - 1 && menu[i + 1].break) {
        currentGroup.push(item);
      } else {
        if (currentGroup.length > 0) {
          currentGroup.push(item);
        } else {
          transformedMenu.push(item);
        }
      }
    }
  }

  return transformedMenu;
}
