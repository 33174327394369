import { cva } from 'class-variance-authority';

export const largeHeaderVariants = cva(
  'bg-brand-background-primary shadow-[0px_4px_8px_1px_rgba(0,0,0,0.08)] transition-all duration-400',
  {
    variants: {
      isTopMenuVisible: {
        true: 'opacity-100 translate-y-0',
        false: 'opacity-100 -translate-y-[2.063rem]',
      },
    },
  }
);
