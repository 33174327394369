import { Link } from '@pbx/shared/ui/common/link';
import { Button } from '@pbx/shared/ui/common/button';
import { Icon } from '@pbx/shared/ui/common/icon';

import { TMainMenu } from './main-menu.types';
import { NavigationLink } from '../navigation-link/navigation-link';
import { MegaMenu } from '../mega-menu/mega-menu';

export function MainMenu({
  mainMenu,
  logo,
  findPropertyPage,
  loginPage,
  bookValuationPage,
  isLoggedIn,
  reviewLinks,
}: TMainMenu) {
  if (!Array.isArray(mainMenu) || !mainMenu?.length) {
    return null;
  }

  return (
    <div className="bg-brand-background-primary flex h-[4.5rem] basis-full items-center justify-between py-3">
      <div className="flex items-center">
        <Link href={'/'} className="shrink-0">
          <img
            src={logo.src}
            alt={logo.alt}
            width={logo.width}
            height={logo.height}
          />
        </Link>
        <MegaMenu mainMenu={mainMenu} reviewLinks={reviewLinks} />
      </div>
      <div>
        <div className="flex gap-2">
          <NavigationLink
            link={findPropertyPage.link.href}
            variant="primary"
            icon={<Icon name="search" width={24} />}
            iconPosition="left"
          >
            {findPropertyPage.label}
          </NavigationLink>
          <NavigationLink
            link={loginPage.link.href}
            variant="primary"
            icon={<Icon name="user" width={24} />}
            iconPosition="left"
          >
            {isLoggedIn ? 'Account' : loginPage.label}
          </NavigationLink>
          {bookValuationPage?.link?.href && (
            <Button variant="primary" inverted={true} className="p-3" asChild>
              <Link
                newTab={bookValuationPage?.newTab}
                href={bookValuationPage?.link?.href}
                className="whitespace-nowrap"
              >
                {bookValuationPage.label}
              </Link>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
