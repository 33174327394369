import { Text } from '@pbx/shared/ui/common/text';

import { TTopMenu } from './top-menu.types';
import { NavigationLink } from '../navigation-link/navigation-link';

export function TopMenu({ links }: TTopMenu) {
  if (!Array.isArray(links) || !links?.length) {
    return null;
  }

  return (
    <div className="bg-background-tertiary container flex gap-1.5 py-0">
      <ul className="flex gap-6 py-1">
        {links?.map(({ label, link }, index) => (
          <li key={`${label}-${index}`}>
            <NavigationLink
              variant="secondary"
              className="px-0"
              link={link.href}
            >
              <Text
                variant="paragraph"
                size="small"
                className="text-background-action hover:underline"
              >
                {label}
              </Text>
            </NavigationLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
