import { Cookies } from 'react-cookie';

export function hasLoggedInCookie(): boolean {
  const cookies = new Cookies();
  const hasCookie = cookies.get('.HashPB');

  if (hasCookie) {
    return true;
  }

  return false;
}
