import React from 'react';

import { Link } from '@pbx/shared/ui/common/link';
import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Text, TTypographyProps } from '@pbx/shared/ui/common/text';

import {
  navigationLinkVariants,
  iconVariants,
} from './navigation-link.variants';
import { TNavigationLinkProps } from './navigation-link.types';

export const NavigationLink = React.forwardRef<
  HTMLAnchorElement,
  TNavigationLinkProps
>(
  (
    {
      className,
      variant,
      icon,
      iconPosition = 'left',
      children,
      link,
      disabled = false,
      textVariant = 'ui',
      textSize = 'medium-500s',
    },
    ref
  ) => {
    const linkTextSize = textSize as TTypographyProps['size'];

    if (disabled) {
      return (
        <div className={cn(navigationLinkVariants({ variant }), className)}>
          {icon && iconPosition === 'left' && (
            <span className={iconVariants({ variant })}>{icon}</span>
          )}
          <Text variant={textVariant} size={linkTextSize}>
            {children}
          </Text>
          {icon && iconPosition === 'right' && (
            <span className={cn(iconVariants({ variant }))}>{icon}</span>
          )}
        </div>
      );
    }

    return (
      <Link
        href={link || ''}
        className={cn(navigationLinkVariants({ variant }), className)}
      >
        {icon && iconPosition === 'left' && (
          <span className={iconVariants({ variant })}>{icon}</span>
        )}
        <Text variant={textVariant} size={linkTextSize}>
          {children}
        </Text>
        {icon && iconPosition === 'right' && (
          <span className={cn(iconVariants({ variant }))}>{icon}</span>
        )}
      </Link>
    );
  }
);
