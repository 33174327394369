import { Text } from '@pbx/shared/ui/common/text';

import { TCopyrightProps } from './copyright.types';

export function Copyright({ copyright, rightsReserved }: TCopyrightProps) {
  if (!copyright && !rightsReserved) {
    return null;
  }

  return (
    <div className="inline-flex sm:flex sm:flex-col md:gap-4 lg:gap-4">
      {copyright && (
        <Text as="p" variant="paragraph" size="small">
          {copyright}
        </Text>
      )}

      {rightsReserved && (
        <Text as="p" variant="paragraph" size="small">
          {rightsReserved}
        </Text>
      )}
    </div>
  );
}
