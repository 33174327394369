import { cva } from 'class-variance-authority';

export const topShapeVariants = cva('', {
  variants: {
    shape: {
      1: 'top-shape-1',
    },
  },
  compoundVariants: [
    {
      shape: [1],
      class: 'pt-4',
    },
  ],
});
