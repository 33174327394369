import * as Accordion from '@radix-ui/react-accordion';

import { Icon } from '@pbx/shared/ui/common/icon';
import { TLink } from '@pbx/shared/contentful/helpers';
import { cn } from '@pbx/shared/utilities/combine-class-names';

import { TMenuAccordion } from './menu-accordion.types';
import { NavigationLink } from '../navigation-link/navigation-link';

export function MenuAccordion({
  mainMenu,
  topMenu,
  className,
}: TMenuAccordion) {
  const renderMainMenu = () => {
    return mainMenu.map(({ title, links }, index) => (
      <Accordion.Item
        key={`${index}-${title?.label}`}
        value={`${index}-${title?.label}`}
        className="mt-px overflow-hidden first:mt-0 focus-within:relative focus-within:z-10"
      >
        <Accordion.Header className="flex">
          <Accordion.Trigger className="hover:bg-background-tertiary group flex flex-1 cursor-default items-center justify-between">
            <NavigationLink
              variant="main-menu-secondary"
              iconPosition="left"
              link={title?.link.href}
              icon={
                <Icon name="disclosure-arrow-down" width={24} height={24} />
              }
              disabled={true}
              textVariant="heading"
              textSize="smaller"
            >
              {title?.label}
            </NavigationLink>
          </Accordion.Trigger>
        </Accordion.Header>
        {renderSubMenu(links, title?.label)}
      </Accordion.Item>
    ));
  };

  const renderSubMenu = (links: TLink[], parentLabel: string | undefined) => {
    return links.map(({ label, link }, index) => (
      <Accordion.Content
        key={`${index}-${parentLabel}`}
        className="hover:bg-background-tertiary data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp px-0"
      >
        <NavigationLink
          variant="main-menu-secondary"
          link={link.href}
          className="w-full justify-start py-3 pl-14 pr-4"
        >
          {label}
        </NavigationLink>
      </Accordion.Content>
    ));
  };

  const renderBottomMenu = () => {
    return topMenu.map((link, index) => (
      <Accordion.Item
        key={`${index}-${link.label}`}
        value={`${index}-${link.label}`}
        className="hover:bg-background-tertiary mt-px flex items-center py-3 first:mt-0 focus-within:relative focus-within:z-10"
      >
        <NavigationLink
          variant="secondary"
          iconPosition="left"
          link={link.link.href}
          icon={<Icon name="arrow-forward" width={24} height={24} />}
          textVariant="heading"
          textSize="smaller"
          className="w-full justify-start"
        >
          {link.label}
        </NavigationLink>
      </Accordion.Item>
    ));
  };

  return (
    <Accordion.Root type="multiple">
      <div
        className={cn(
          'bg-brand-foreground-primary no-scrollbar max-h-[75vh] overflow-y-scroll',
          className
        )}
      >
        {renderMainMenu()}
        {renderBottomMenu()}
      </div>
    </Accordion.Root>
  );
}
