import { useState, useEffect } from 'react';

import { LinkButton } from '@pbx/shared/ui/common/link-button';
import { Icon } from '@pbx/shared/ui/common/icon';
import { Link } from '@pbx/shared/ui/common/link';
import { Text } from '@pbx/shared/ui/common/text';
import { screenTokens } from '@pbx/styles/core/screen';

import { MobileDropdownMenu } from './mobile-dropwdown-menu/mobile-dropdown-menu';
import { THeaderProps } from './header.types';
import { headerVariants } from './header.variants';

export const HIDE_HEADER_SCROLL_BREAKPOINT = 320;
export const MEDIUM_SCREEN_BREAKPOINT = parseInt(screenTokens.md.max);

export function HeaderMortgages({
  mainMenu,
  logoDesktop,
  logoMobile,
  secondaryAction,
  primaryAction,
}: THeaderProps) {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  useEffect(() => {
    let lastScrollY = 0;
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrollingDown = currentScrollY > lastScrollY;

      if (MEDIUM_SCREEN_BREAKPOINT <= window.innerWidth) {
        setIsHeaderVisible(true);
      } else {
        setIsHeaderVisible(
          !isScrollingDown || currentScrollY <= HIDE_HEADER_SCROLL_BREAKPOINT
        );
        lastScrollY = currentScrollY;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={headerVariants({ isHeaderVisible })}>
      <div className="container">
        <div className="flex flex-1 flex-row items-center gap-4 sm:py-2 md:py-2 lg:py-3">
          <div className="flex gap-5">
            <div className="flex items-center lg:hidden">
              <MobileDropdownMenu
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
                mainMenu={mainMenu}
              />
            </div>
            <div className="flex items-center sm:max-w-[145px] md:max-w-[145px] lg:max-w-[297px]">
              <Link href="/">
                <img
                  src={logoMobile.src}
                  alt={logoMobile.alt}
                  height={logoMobile.height}
                  width={logoMobile.width}
                  className="hidden sm:block md:block"
                />
                <img
                  src={logoDesktop.src}
                  alt={logoDesktop.alt}
                  height={logoDesktop.height}
                  width={logoDesktop.width}
                  className="hidden lg:block"
                />
              </Link>
            </div>
          </div>
          <div className="flex shrink-0 sm:hidden md:hidden">
            {mainMenu?.map((item, index) => (
              <Link
                key={index}
                href={item?.link?.href}
                newTab={!!item?.newTab}
                className="hover:bg-background-tint rounded-small flex items-center gap-2 p-3 transition-colors"
              >
                <Text
                  variant="ui"
                  size="medium-500"
                  className="text-foreground-primary"
                >
                  {item?.label}
                </Text>
              </Link>
            ))}
          </div>

          <div className="flex min-w-fit flex-1 shrink-0 items-center justify-end gap-3">
            {secondaryAction?.link?.href && (
              <Link
                href={secondaryAction?.link?.href}
                newTab={!!secondaryAction?.newTab}
                className="sm:text-foreground-action md:text-foreground-action hover:bg-background-tint rounded-small lg:text-foreground-primary flex items-center text-center transition-colors sm:flex-col sm:gap-0 md:flex-col md:gap-0 lg:gap-2 lg:p-3"
              >
                <Icon name="search" width={24} />
                <Text
                  variant="ui"
                  size="medium-500"
                  className="sm:text-minus-2 md:text-minus-2"
                >
                  {secondaryAction?.label || ''}
                </Text>
              </Link>
            )}
            {primaryAction?.link?.href && (
              <LinkButton
                variant="primary"
                link={primaryAction?.link}
                newTab={!!primaryAction?.newTab}
                label={primaryAction?.label || ''}
                className="sm:hidden"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
