import { Text } from '@pbx/shared/ui/common/text';
import { Link } from '@pbx/shared/ui/common/link';

import { TSecondaryMenuProps } from './secondary-menu.types';

export function SecondaryMenu({ secondaryMenu }: TSecondaryMenuProps) {
  if (!Array.isArray(secondaryMenu) || !secondaryMenu?.length) {
    return null;
  }

  return (
    <ul>
      {secondaryMenu.map(({ link, label, newTab }, index) => (
        <li
          className="mr-5 md:inline-block lg:inline-block"
          key={`${label}-${index}`}
        >
          <Text variant="paragraph" size="small">
            <Link
              key={index}
              href={link.href}
              newTab={newTab}
              className="hover:underline"
            >
              {label}
            </Link>
          </Text>
        </li>
      ))}
    </ul>
  );
}
