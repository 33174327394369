import { useState, useEffect } from 'react';

import { Link } from '@pbx/shared/ui/common/link';
import { Button } from '@pbx/shared/ui/common/button';
import { Icon } from '@pbx/shared/ui/common/icon';

import { TBaseAndMediumHeader } from './base-and-medium-header.types';
import { NavigationLink } from '../navigation-link/navigation-link';
import { SmallNavigationMenu } from '../small-navigation-menu/small-navigation-menu';
import { HIDE_HEADER_SCROLL_BREAKPOINT } from '../header.constants';
import { baseAndSmallHeaderVariants } from './base-and-medium-header.variants';

export function BaseAndMediumHeader({
  topMenu,
  mainMenu,
  logoMobile,
  findPropertyPage,
  loginPage,
  bookValuationPage,
  isLoggedIn,
}: TBaseAndMediumHeader) {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  useEffect(() => {
    let lastScrollY = 0;
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrollingDown = currentScrollY > lastScrollY;

      setIsHeaderVisible(
        !isScrollingDown || currentScrollY <= HIDE_HEADER_SCROLL_BREAKPOINT
      );
      lastScrollY = currentScrollY;
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={baseAndSmallHeaderVariants({ isHeaderVisible })}>
      <div className="flex items-center gap-2">
        <SmallNavigationMenu
          mainMenu={mainMenu}
          topMenu={topMenu}
          bookValuationPage={bookValuationPage}
        />
        <div className="shrink-0 p-3">
          <Link href={'/'}>
            <img
              src={logoMobile.src}
              alt={logoMobile.alt}
              width={logoMobile.width}
              height={logoMobile.height}
            />
          </Link>
        </div>
      </div>
      <div className="flex-shrink-1 flex items-center gap-3">
        <NavigationLink
          link={findPropertyPage.link.href}
          variant="primary"
          icon={<Icon name="search" width={24} />}
          className="sm:px-0 md:px-0"
        >
          {findPropertyPage.label}
        </NavigationLink>
        <NavigationLink
          link={loginPage.link.href}
          variant="primary"
          icon={<Icon name="user" width={24} />}
          className="sm:px-0 md:px-0"
        >
          {isLoggedIn ? 'Account' : loginPage.label}
        </NavigationLink>
        <div className="items-center sm:hidden">
          {bookValuationPage?.link?.href && (
            <Button variant="primary" inverted={true} size="small" asChild>
              <Link
                href={bookValuationPage?.link?.href}
                className="whitespace-nowrap"
                newTab={bookValuationPage?.newTab}
              >
                {bookValuationPage.label}
              </Link>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
