import { Fragment } from 'react';

import { Text } from '@pbx/shared/ui/common/text';
import { cn } from '@pbx/shared/utilities/combine-class-names';
import { TMenu } from '@pbx/shared/contentful/helpers';
import { Link } from '@pbx/shared/ui/common/link';

import { groupByMenuBreaks } from './helpers/helpers';
import { liVariants, ulVariants } from './menu.variants';
import { TMenusProps } from './menus.types';

export function Menus({ menus = [] }: TMenusProps) {
  const transformedMenus = groupByMenuBreaks(menus);

  const renderMenu = (menu: (TMenu | TMenu[])[] | TMenu) => {
    if (Array.isArray(menu)) {
      return (
        <div className="grid auto-rows-max gap-8">
          {menu.map((menuItem: TMenu | TMenu[], index: number) => (
            <div key={`menu_block_${index}`}>{renderMenu(menuItem)}</div>
          ))}
        </div>
      );
    }

    const { title, links = [] } = menu || {};
    const hasIcon = links?.every(({ icon }) => icon);

    return (
      <div>
        <Text as="h3" variant="heading" size="smaller" className="mb-6 block">
          {title?.label}
        </Text>
        <ul className={ulVariants({ image: hasIcon })}>
          {links?.map(({ label, link, icon, newTab, ...rest }, index) => (
            <li
              key={`${label}-${index}`}
              className={cn(liVariants({ image: !!icon }))}
            >
              <Link
                href={link.href}
                className="hover:underline"
                newTab={newTab}
                {...rest}
              >
                {icon ? (
                  <img
                    src={icon.src}
                    alt={icon.alt}
                    width={icon.width}
                    height={icon.height}
                    loading="lazy"
                    className="max-h-6 max-w-full"
                  />
                ) : (
                  <Text variant="ui" size="medium-500" className="">
                    {label}
                  </Text>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="grid gap-8 md:auto-cols-fr md:grid-flow-col lg:auto-cols-fr lg:grid-flow-col">
      {transformedMenus.map((menu, index) => (
        <Fragment key={`parent_menu_block_${index}`}>
          {renderMenu(menu)}
        </Fragment>
      ))}
    </div>
  );
}
